import paymentPhpSdkIcon from '../../assets/logos/paiement-sdk-php.svg';
import paymentSdkIcon from '../../assets/logos/paiement-sdk-js.svg';
import paymentModuleIcon from '../../assets/logos/paiement-module.svg';
import paymentApiIcon from '../../assets/logos/paiement-api.svg';
import charityKitApiIcon from '../../assets/logos/charityKit-api.svg';
import charityKitSdkIcon from '../../assets/logos/charityKit-sdk-js.svg';
import charityKitModuleIcon from '../../assets/logos/charityKit-module.svg';
import climateKitApiIcon from '../../assets/logos/climateKit-api.svg';
import climateKitSdkIcon from '../../assets/logos/climateKit-sdk-js.svg';
import climateKitWidgetIcon from '../../assets/logos/climateKit-widget-co2.svg';

const toolsCardData = [
    {
        title: 'paymentApiTitle',
        text: 'Lorem Ipsum Store Ipsum',
        link: '/payment/api',
        icon: paymentApiIcon,
    },
    {
        title: 'paymentModuleTitle',
        text: 'Lorem Ipsum Store Ipsum',
        link: '/payment/modules',
        icon: paymentModuleIcon,
    },
    {
        title: 'paymentSdkJsTitle',
        text: 'Lorem Ipsum Store Ipsum',
        link: '/payment/sdk-js',
        icon: paymentSdkIcon,
    },
    {
        title: 'paymentSdkPhpTitle',
        text: 'Lorem Ipsum Store Ipsum',
        link: '/payment/sdk-php',
        icon: paymentPhpSdkIcon,
    },
    {
        title: 'climateKitApiTitle',
        text: 'Lorem Ipsum Store Ipsum',
        link: '/climateKit/api',
        icon: climateKitApiIcon,
    },
    {
        title: 'climateKitSdkJsTitle',
        text: 'Lorem Ipsum Store Ipsum',
        link: '/climateKit/sdk-js',
        icon: climateKitSdkIcon,
    },
    {
        title: 'climateKitWidgetTitle',
        text: 'Lorem Ipsum Store Ipsum',
        link: '/climateKit/widget',
        icon: climateKitWidgetIcon,
    },
    {
        title: 'charityKitApiTitle',
        text: 'Lorem Ipsum Store Ipsum',
        link: '/charityKit/api',
        icon: charityKitApiIcon,
    },
    {
        title: 'charityKitModuleTitle',
        text: 'Lorem Ipsum Store Ipsum',
        link: '/charityKit/modules',
        icon: charityKitModuleIcon,
    },
    {
        title: 'charityKitSdkJsTitle',
        text: 'Lorem Ipsum Store Ipsum',
        link: '/charityKit/sdk-js',
        icon: charityKitSdkIcon,
    },
];

export { toolsCardData };
