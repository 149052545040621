import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
    Banner,
    Card,
    Grid,
    Text,
    Title,
    ThemeDefault,
} from '@paygreen/paygreen-ui';
import { Layout, SEO, ToolsCard, FeaturesSection } from '../components';
import { toolsCardData } from '../utils/toolsCardData';
import { featuresData } from '../utils/featuresData';

const ResponsiveGrid = styled.div`
    .internal-grid {
        grid-template-columns: 1fr 1fr;
        display: grid;

        @media (${ThemeDefault.query.min.md}) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
`;

const Home = () => {
    const { t } = useTranslation('pages');

    return (
        <Layout>
            <SEO
                title={t('homepage.seoTitle')}
                keywords={['Paygreen', 'development', 'documentation']}
            />

            <Banner gradient="brand" topStyle="none" isFirstContent={true}>
                <Grid alignItems="center">
                    <Card hasBackground={false} blockWidth="xl">
                        <Title
                            htmlTag="h1"
                            colorType="reverse"
                            textSize="xl"
                            hasUnderline={true}
                            marginTop="sm"
                        >
                            {t('homepage.title')}
                        </Title>

                        <Text colorType="reverse" textSize="md">
                            {t('homepage.intro')}
                        </Text>
                    </Card>
                </Grid>
            </Banner>

            <ResponsiveGrid>
                <Grid
                    className="internal-grid"
                    childrenMarginBig="none"
                    childrenMargin="none"
                    displayType="grid"
                    gridGap="md"
                    justifyItems="stretch"
                    alignItems="stretch"
                >
                    {toolsCardData.map((card, index) => (
                        <ToolsCard
                            key={index}
                            link={card.link}
                            icon={card.icon}
                            title={t(`homepage.tools.${card.title}`)}
                            text={card.text}
                        />
                    ))}
                </Grid>
            </ResponsiveGrid>

            {featuresData.map((data, index) => (
                <FeaturesSection key={index} index={index} data={data} />
            ))}
        </Layout>
    );
};

export default Home;
